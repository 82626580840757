import { user } from '@/api'
import { cookie } from '@/utils'
import md5 from 'md5';
import { compileToFunctions } from 'vue-template-compiler';
import { _ } from 'core-js';
export default {
    name: 'User',

    created () {
        // 判断是否登录
        if (!this.isLogin) return this.$router.push({ name: 'Login', query: {url: window.location.href} })
    },

    methods: {
        // 退出登录
        handleLogout () {
            if (this.isAjax) return
            this.$dialog.confirm({
                title: '提示',
                message: '退出登录？',
                confirmButtonColor: '#5ec2b5',
            }).then(() => {
                this.isAjax = true
                user.logout().then(res => {
                    this.isAjax = false
                    if (res.status != 1) return this.$toast(res.message);
                    _global.isLogin = false
                    _global.token = ''
                    _global.uid = ''
                    _global.userInfo = {}
                    _global.lists = []
                    cookie.delCookie('token')
                    cookie.delCookie('uid')
                    this.$toast('退出登录成功');
                    setTimeout(() => {
                        this.$router.push({name: 'Ceping'})
                    }, 500)
                }).catch(err => {
                    console.log(err)
                    this.isAjax = false
                })
            }).catch(() => {
                // do somethind ...  
            })
        },

        // 路由跳转
        handleToRouter (index) {
            switch (index) {
                case 0:
                    this.$router.push({name: 'Find'})
                break;
                case 1:
                    this.$router.push({name: 'View', query: {type: 'kefu'}})
                break;
                case 2:
                    this.$router.push({name: 'View', query: {type: 'cjwt'}})
                break;
            }
        }

    },

    data() {
        return {
            isAjax: false,
            userInfo: _global.userInfo,
            isLogin: _global.isLogin,
            isWeixin: _global.wx,
            items: [
                { title: '我的订单', url: 'Find', icon: '' },
                { title: '我的客服', url: 'kefu', icon: '' },
                { title: '常见问题', url: 'cjwt', icon: '' }
            ]
        }
    }

}